import React from 'react';
import C4DVideoPlayerContainer from '@carbon/ibmdotcom-web-components/es/components-react/video-player/video-player-container';
import { FigmaConfig, segmentTracking } from '../types/types';

interface VideoCompProps {
  configObj: FigmaConfig; // Video identifier (could be a YouTube, Vimeo ID, etc.)
}

const VideoComp: React.FC<VideoCompProps> = ({ configObj }) => {
  return (
    <div>
      <C4DVideoPlayerContainer
        aspectRatio="16x9"
        videoId={
          configObj.video_id
            ? configObj.video_id
            : configObj.education_video.video_id
        }
        onClick={() => {
          segmentTracking('CTA Clicked', {
            productTitle: 'DIAC Demo - ' + configObj.product_title,
            pageTitle: 'DIAC Demo - ' + configObj.demo_title,
            object: 'Video player - ' + configObj.demo_title,
            resultValue: 'CTA clicked',
            CTA: 'Video clicked',
            location: '',
            action: `Playing media video`,
            field: '',
          });
        }}
      />
    </div>
  );
};

export default VideoComp;
